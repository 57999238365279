// playerPool.js
import { addBreadcrumb } from '@sentry/browser';
import genericPool from 'generic-pool';
import { player as playerDebug } from './debug.js';
import { Severity } from './sentry.js';
import { create, destroy, getVideoElementPool } from './shakaFactory.js';

const playerPoolDebug = playerDebug.extend('log:playerPool');
const playerLog = ({ messages, data }) => {
  addBreadcrumb({
    category: 'player',
    level: Severity.Info,
    message: messages.join(' '),
    data,
  });
  playerPoolDebug(...messages, data);
};

const getDebugNo = () => {
  let num = Math.floor(Math.random() * 1000);
  num = num.toString();
  while (num.length < 3) num = '0' + num; // pad
  return num;
};

const factory = {
  create: function () {
    const debugNo = getDebugNo();
    playerLog({ messages: [`(debugNo ${debugNo}) create`] });
    return create({ debugNo });
  },
  destroy: function (player) {
    const debugNo = getDebugNo();
    playerLog({ messages: [`(debugNo ${debugNo}) destroy`] });
    return destroy({ debugNo, player });
  },
};

const opts = {
  // Maximum size of the pool
  max: 1,

  // Minimum size of the pool
  // We set it as 0 intentionally:
  // 1. Lazily initialize the player.
  // 2. Manually initialize it to ensure all the data (required by players) are ready.
  min: 0,
};

const playerPool = genericPool.createPool(factory, opts);

if (typeof window !== 'undefined') {
  // For debugging
  window.playerPool = playerPool;
}

/**
 * When `acquirePlayer` is called,
 * genericPool would call the corresponding `create` automatically.
 * We also keep track of pool related information here.
 * @return {Promise} promise resolved with a shaka player instance
 */
Object.defineProperty(playerPool, 'acquirePlayer', {
  writable: false,
  value: function () {
    playerLog({
      messages: ['acquirePlayer', 'current pool state b4 acquiring'],
      data: {
        pool: {
          available: playerPool.available,
          borrowed: playerPool.borrowed,
          max: playerPool.max,
          min: playerPool.min,
          pending: playerPool.pending,
          size: playerPool.size,
          spareResourceCapacity: playerPool.spareResourceCapacity,
        },
      },
    });
    return this.acquire();
  },
});

/**
 * When `releasePlayer` is called,
 * genericPool would call the corresponding `destroy` automatically.
 * We also keep track of pool related information here.
 * @param {object} player - a specific shaka player instance needed to be release
 * @return {Promise} promise
 */
Object.defineProperty(playerPool, 'releasePlayer', {
  writable: false,
  value: function (player) {
    playerLog({
      messages: ['releasePlayer', 'current pool state b4 releasing'],
      data: {
        player: {
          serialNumber: player.serialNumber,
        },
        pool: {
          available: playerPool.available,
          borrowed: playerPool.borrowed,
          max: playerPool.max,
          min: playerPool.min,
          pending: playerPool.pending,
          size: playerPool.size,
          spareResourceCapacity: playerPool.spareResourceCapacity,
        },
      },
    });
    return this.release(player);
  },
});

/**
 * It aims to provide the information of the instances managed by playerPool
 * @return {object} a status object for the playerPool
 */
Object.defineProperty(playerPool, 'getStatus', {
  writable: false,
  value: function () {
    return {
      available: playerPool.available,
      borrowed: playerPool.borrowed,
      pending: playerPool.pending,
      spareResourceCapacity: playerPool.spareResourceCapacity,
    };
  },
});

/**
 * @param {object} option
 * @param {boolean} option.autoplay - if the video wants to automatically start playing
 * @param {boolean} option.loop - if the video needs to start over when it's finished
 * @param {boolean} option.muted - the audio output should be muted or not
 * @return {void}
 */
Object.defineProperty(playerPool, 'updateVideoElementPool', {
  writable: false,
  value: function (option) {
    const videoElementPool = getVideoElementPool();
    videoElementPool.forEach(videoElement => {
      for (const [key, value] of Object.entries(option)) {
        videoElement[key] = value;
      }
    });
  },
});

export default playerPool;
