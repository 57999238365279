// formatVodDrmSource.js
'use strict';
import { addBreadcrumb } from '@sentry/browser';

import {
  COM_APPLE_FPS,
  COM_MICROSOFT_PLAYREADY,
  COM_MICROSOFT_PLAYREADY_HARDWARE,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
  COM_WIDEVINE_ALPHA,
  ORG_W3_CLEARKEY,
} from '../resource/drmConstants.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { player as playerDebug } from '../resource/debug.js';
import { Severity } from '../resource/sentry.js';

const AUDIO_CONTENT_TYPE = 'audio/mp4; codecs="mp4a.40.2"'; // TODO: remote config
const VIDEO_CONTENT_TYPE = 'video/mp4; codecs="avc1.640028"'; // TODO: remote config

const playerDebugLog = playerDebug.extend('log:formatVodDrmSource');
const playerLog = ({ messages, data }) => {
  addBreadcrumb({
    category: 'player',
    level: Severity.Info,
    message: messages.join(' '),
    data,
  });
  playerDebugLog(...messages, data);
};

// https://stackoverflow.com/a/41106346
export const getWidevineServerCertificateBuffer = ({
  widevineServerCertificateBase64,
}) =>
  Uint8Array.from(atob(widevineServerCertificateBase64), c => c.charCodeAt(0));

const formatFairPlay = ({ source, messageId, fetchDrmLicense }) => {
  playerLog({
    messages: ['formatFairPlay', 'init'],
    data: { source, messageId },
  });
  return {
    ...source,
    keySystems: {
      [`${COM_APPLE_FPS}.1_0`]: {
        audioContentType: AUDIO_CONTENT_TYPE,
        videoContentType: VIDEO_CONTENT_TYPE,
        certificateUri: getResourceUrl({
          endpoint: '/drm/certs/com.apple.fps.cer',
        })?.href,
        getContentId: (emeOptions, initDataUnit16ArrayString) => {
          // https://github.com/Axinom/drm-quick-start/blob/82d81dad82b802c4332537f4964835b47601f608/Website/fairplay.html#L137
          const result = initDataUnit16ArrayString?.replace(/^.*:\/\//, '');
          playerLog({
            messages: ['formatFairPlay', 'getContentId'],
            data: {
              source,
              messageId,
              emeOptions,
              initDataUnit16ArrayString,
              result,
            },
          });
          return result;
        },
        getLicense: (emeOptions, contentId, requestLicenseBody, callback) => {
          playerLog({
            messages: ['formatFairPlay', 'getLicense'],
            data: {
              source,
              messageId,
              emeOptions,
              contentId,
              requestLicenseBody,
            },
          });
          fetchDrmLicense({
            keySystem: COM_APPLE_FPS,
            requestLicenseBody,
            messageId,
            callback,
            kId: contentId,
          });
        },
      },
    },
  };
};

const formatPlayReady = ({
  source,
  messageId,
  keySystem,
  robustness,
  fetchDrmLicense,
}) => {
  playerLog({
    messages: ['formatPlayReady', 'init'],
    data: { source, messageId, keySystem, robustness },
  });
  return {
    ...source,
    keySystems: {
      [keySystem]: {
        audioContentType: AUDIO_CONTENT_TYPE,
        videoContentType: VIDEO_CONTENT_TYPE,
        videoRobustness: robustness ? robustness : undefined,
        getLicense: (emeOptions, requestLicenseBody, callback, kId) => {
          playerLog({
            messages: ['formatPlayReady', 'getLicense'],
            data: {
              source,
              messageId,
              keySystem,
              robustness,
              emeOptions,
              requestLicenseBody,
              kId,
            },
          });
          fetchDrmLicense({
            requestLicenseBody,
            keySystem: COM_MICROSOFT_PLAYREADY,
            messageId,
            callback,
            kId,
          });
        },
      },
    },
  };
};

const formatWidevine = ({
  source,
  messageId,
  robustness,
  fetchDrmLicense,
  widevineServerCertificateBase64,
}) => {
  playerLog({
    messages: ['formatWidevine', 'init'],
    data: { source, messageId, robustness },
  });
  if (!robustness) return source;
  let getCertificate = null;
  if (widevineServerCertificateBase64) {
    getCertificate = (emeOptions, callback) => {
      const widevineServerCertificateBuffer =
        getWidevineServerCertificateBuffer({ widevineServerCertificateBase64 });
      playerLog({
        messages: ['formatWidevine', 'getCertificate'],
        data: {
          emeOptions,
          widevineServerCertificateBase64,
          widevineServerCertificateBuffer,
        },
      });
      callback(null, widevineServerCertificateBuffer);
    };
  }
  return {
    ...source,
    keySystems: {
      [`${COM_WIDEVINE_ALPHA}`]: {
        audioContentType: AUDIO_CONTENT_TYPE,
        videoContentType: VIDEO_CONTENT_TYPE,
        videoRobustness: robustness,
        getCertificate,
        getLicense: (emeOptions, requestLicenseBody, callback, kId) => {
          playerLog({
            messages: ['formatWidevine', 'getLicense'],
            data: {
              source,
              messageId,
              robustness,
              emeOptions,
              requestLicenseBody,
              kId,
            },
          });
          fetchDrmLicense({
            keySystem: COM_WIDEVINE_ALPHA,
            requestLicenseBody,
            messageId,
            callback,
            kId,
          });
        },
      },
    },
  };
};

const formatClearKey = ({ source, messageId, fetchDrmLicense }) => {
  playerLog({
    messages: ['formatClearKey', 'init'],
    data: { source, messageId },
  });
  return {
    ...source,
    keySystems: {
      [ORG_W3_CLEARKEY]: {
        //initDataTypes: ['cenc', 'webm'],
        audioContentType: AUDIO_CONTENT_TYPE,
        videoContentType: VIDEO_CONTENT_TYPE,
        getLicense: (emeOptions, requestLicenseBody, callback, kId) => {
          playerLog({
            messages: ['formatClearKey', 'getLicense'],
            data: {
              source,
              messageId,
              emeOptions,
              requestLicenseBody,
              kId,
            },
          });
          fetchDrmLicense({
            requestLicenseBody,
            keySystem: ORG_W3_CLEARKEY,
            messageId,
            callback,
            kId,
          });
        },
      },
    },
  };
};

/**
 * Format vod drm source
 * @param {object} {source} - vod source.
 * @param {string} {source.type} - vod source type.
 * @param {string} {source.src} - vod source url.
 * @param {string} {messageId} - message id.
 * @param {Redux.Action} {fetchDrmLicense} - fetch drm license.
 * @param {string} {robustness} - drm robustness.
 * @param {string} {keySystem} - drm key system.
 * @param {string} {widevineServerCertificateBase64} - widevine certificate
 */
const formatVodDrmSource = ({
  source,
  messageId,
  robustness,
  keySystem,
  fetchDrmLicense,
  widevineServerCertificateBase64,
}) => {
  playerLog({
    messages: ['formatVodDrmSource'],
    data: { source, messageId, robustness, keySystem },
  });
  if (keySystem === COM_APPLE_FPS) {
    return formatFairPlay({
      source,
      messageId,
      fetchDrmLicense,
    });
  } else if (
    [
      COM_MICROSOFT_PLAYREADY,
      COM_MICROSOFT_PLAYREADY_HARDWARE,
      COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
      COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
    ].includes(keySystem)
  ) {
    return formatPlayReady({
      source,
      messageId,
      keySystem,
      robustness,
      fetchDrmLicense,
    });
  } else if (keySystem === COM_WIDEVINE_ALPHA) {
    return formatWidevine({
      source,
      messageId,
      robustness,
      fetchDrmLicense,
      widevineServerCertificateBase64,
    });
  } else {
    const result = formatClearKey({
      source,
      messageId,
      fetchDrmLicense,
    });
    playerLog({
      messages: ['formatVodDrmSource', 'clearkey'],
      data: result,
    });
    return result;
  }
};

export default formatVodDrmSource;
